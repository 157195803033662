import Finder from './pages/Finder';

const App = () => {
	const finderApiUrl = 'https://apis.camillerakoto.fr/finder-node';

	return (
		<>
			<a href="https://camillerakoto.fr" target="_blank">
				<p className="author">Créé avec ❤️ par Camille Rakotoarisoa</p>
			</a>
			<Finder finderApiUrl={finderApiUrl} />
		</>
	);
};

export default App;
