import axios from 'axios';

const SelectFileFromComputer = ({
	finderApiUrl,
	currentFolder,
	setCurrentFilesAndFolders,
}) => {
	const fileChangedHandler = async (event) => {
		const selectedFile = event.target.files[0];

		const formData = new FormData();
		formData.append('myFile', selectedFile, selectedFile.name);
		formData.append('fileName', selectedFile.name);
		formData.append('id_parent_folder', currentFolder.id);

		const response = await axios.post(
			`${finderApiUrl}/files-and-folders/upload-file`,
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			}
		);

		setCurrentFilesAndFolders(response.data);
	};

	return (
		<div className="SelectFileFromComputer">
			<input type="file" onChange={fileChangedHandler} />
		</div>
	);
};

export default SelectFileFromComputer;
