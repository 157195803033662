import Topbar from '../components/Content/Topbar';
import FilesAndFolders from '../components/Content/FilesAndFolders';

const Content = ({
	currentFolder,
	setCurrentFolder,
	currentFilesAndFolders,
	setCurrentFilesAndFolders,
	finderApiUrl,
}) => {
	return (
		<div className="Content">
			<div className="topbar">
				<Topbar currentFolder={currentFolder} />
			</div>
			<div className="files-and-folders">
				<FilesAndFolders
					setCurrentFolder={setCurrentFolder}
					currentFilesAndFolders={currentFilesAndFolders}
					setCurrentFilesAndFolders={setCurrentFilesAndFolders}
					finderApiUrl={finderApiUrl}
					currentFolder={currentFolder}
				/>
			</div>
		</div>
	);
};

export default Content;
