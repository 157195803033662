import Tab from '../components/Toolbar/Tab';

const Toolbar = ({
	setCurrentFolder,
	currentFilesAndFolders,
	setCurrentFilesAndFolders,
	finderApiUrl,
}) => {
	const tabs = [
		{
			icon: 'images/desktop.png',
			name: 'Desktop',
			id: 'defaultFolder-Desktop',
		},
		{
			icon: 'images/documents.png',
			name: 'Documents',
			id: 'defaultFolder-Documents',
		},
		{
			icon: 'images/download.png',
			name: 'Download',
			id: 'defaultFolder-Download',
		},
		{
			icon: 'images/projects.png',
			name: 'Projects',
			id: 'defaultFolder-Projects',
		},
	];

	return (
		<div className="Toolbar">
			<div className="threeButtons">
				<div className="color-button red"></div>
				<div className="color-button yellow"></div>
				<div className="color-button green"></div>
			</div>

			<div className="tabs">
				<small>Favoris</small>
				{tabs.map((tab) => {
					return (
						<Tab
							tab={tabs[tabs.indexOf(tab)]}
							setCurrentFolder={setCurrentFolder}
							currentFilesAndFolders={currentFilesAndFolders}
							setCurrentFilesAndFolders={setCurrentFilesAndFolders}
							finderApiUrl={finderApiUrl}
							key={tab.id}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default Toolbar;
