import { useState } from 'react';

import Toolbar from '../components/Toolbar';
import Content from '../components/Content';

const Finder = ({ finderApiUrl }) => {
	const [currentFolder, setCurrentFolder] = useState({
		name: 'Dossier inconnu',
	});
	const [currentFilesAndFolders, setCurrentFilesAndFolders] = useState(null);

	return (
		<>
			<div className="Finder">
				<div className="left">
					<Toolbar
						setCurrentFolder={setCurrentFolder}
						currentFilesAndFolders={currentFilesAndFolders}
						setCurrentFilesAndFolders={setCurrentFilesAndFolders}
						finderApiUrl={finderApiUrl}
					/>
				</div>
				<div className="right">
					<Content
						currentFolder={currentFolder}
						setCurrentFolder={setCurrentFolder}
						currentFilesAndFolders={currentFilesAndFolders}
						setCurrentFilesAndFolders={setCurrentFilesAndFolders}
						finderApiUrl={finderApiUrl}
					/>
				</div>
			</div>

			<h3 className="not-for-mobile">
				😢 Un outil non accessible pour mobile.
			</h3>
		</>
	);
};

export default Finder;
