import axios from 'axios';

const FileAndFolder = ({
	setCurrentFolder,
	setCurrentFilesAndFolders,
	fileOrFolder,
	finderApiUrl,
}) => {
	const handleClick = async () => {
		if (fileOrFolder.type === 'folder') {
			setCurrentFolder(fileOrFolder);
			// const formData = new FormData();
			// formData.append('id_parent_folder', fileOrFolder.id);
			const response = await axios.post(
				`${finderApiUrl}/files-and-folders/get-files-and-folders-from-folder`,
				{ id_parent_folder: fileOrFolder.id }
			);
			setCurrentFilesAndFolders(response.data);
		} else if (fileOrFolder.type === 'file') {
			const fileName = fileOrFolder.name
				.replace('&amp;', '&')
				.replace('&lt;', '<')
				.replace('&gt;', '>')
				.replace('&quot;', '"')
				.replace('&#039;', "'");
			window.open(`${finderApiUrl}/uploaded_files/${fileName}`, 'Download');
		}
	};

	//If the file name contains more than 10 characters, then we show only 10 characters and the extension (.png, .zip, and so on...)
	const fileOrFolderNameConfig =
		fileOrFolder.type === 'file' && fileOrFolder.name.length > 10
			? `${fileOrFolder.name.substring(0, 10)}.${fileOrFolder.name
					.split('.')
					.pop()}`
			: fileOrFolder.name;

	return (
		<div className="FileAndFolder" onClick={handleClick}>
			{fileOrFolder.type === 'folder' && (
				<img src="images/folder.png" alt={fileOrFolder.name} />
			)}
			{fileOrFolder.type === 'file' && (
				<img src="images/file.png" alt={fileOrFolder.name} />
			)}
			<p
				dangerouslySetInnerHTML={{
					__html: fileOrFolderNameConfig,
				}}
			></p>
		</div>
	);
};

export default FileAndFolder;
