const Topbar = ({ currentFolder }) => {
	return (
		<div className="Topbar">
			{currentFolder !== null && (
				<img src="images/topbar-folder-icon.png" alt="folder" />
			)}
			{currentFolder !== null && <h1>{currentFolder.name}</h1>}
		</div>
	);
};

export default Topbar;
