import FileAndFolder from './FilesAndFolders/FileAndFolder';
import SelectFileFromComputer from './FilesAndFolders/SelectFileFromComputer';
import CreateFolder from './FilesAndFolders/CreateFolder';

const FilesAndFolders = ({
	currentFolder,
	setCurrentFolder,
	currentFilesAndFolders,
	setCurrentFilesAndFolders,
	finderApiUrl,
}) => {
	if (currentFilesAndFolders !== null) {
		return (
			<>
				<div className="buttons">
					<SelectFileFromComputer
						finderApiUrl={finderApiUrl}
						currentFolder={currentFolder}
						setCurrentFilesAndFolders={setCurrentFilesAndFolders}
					/>
					<CreateFolder
						finderApiUrl={finderApiUrl}
						currentFolder={currentFolder}
						setCurrentFilesAndFolders={setCurrentFilesAndFolders}
					/>
				</div>

				<div className="FilesAndFolders">
					{currentFilesAndFolders.map((fileOrFolder) => (
						<FileAndFolder
							fileOrFolder={fileOrFolder}
							setCurrentFolder={setCurrentFolder}
							setCurrentFilesAndFolders={setCurrentFilesAndFolders}
							finderApiUrl={finderApiUrl}
							key={fileOrFolder.id}
						/>
					))}
				</div>
			</>
		);
	} else {
		return null;
	}
};

export default FilesAndFolders;
