import axios from 'axios';
import { useState } from 'react';

const CreateFolder = ({
	finderApiUrl,
	currentFolder,
	setCurrentFilesAndFolders,
}) => {
	const [showInputName, setShowInputName] = useState(false);
	const handleSubmit = async (e) => {
		e.preventDefault();
		let folderName = e.target['folder_name'].value;
		folderName === '' && (folderName = 'New Folder');

		// const formData = new FormData();
		// formData.append('name', folderName);
		// formData.append('id_parent_folder', currentFolder.id);

		const response = await axios.post(
			`${finderApiUrl}/files-and-folders/create-folder`,
			{ name: folderName, id_parent_folder: currentFolder.id }
		);

		setCurrentFilesAndFolders(response.data);
		setShowInputName(false);
	};

	return (
		<div className="CreateFolder">
			{!showInputName && (
				<button onClick={() => setShowInputName(true)}>Créer un dossier</button>
			)}
			{showInputName && (
				<form onSubmit={handleSubmit}>
					<input
						type="text"
						name="folder_name"
						placeholder="Nom du dossier"
						maxlength="10"
						autoComplete="off"
					></input>
					<button type="submit">Créer</button>
				</form>
			)}
		</div>
	);
};

export default CreateFolder;
