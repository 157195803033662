import axios from 'axios';

const Tab = ({
	tab,
	setCurrentFolder,
	currentFilesAndFolders,
	setCurrentFilesAndFolders,
	finderApiUrl,
}) => {
	const handleClick = async () => {
		setCurrentFolder(tab);
		// const formData = new FormData();
		// formData.append('id_parent_folder', tab.id);
		const response = await axios.post(
			`${finderApiUrl}/files-and-folders/get-files-and-folders-from-folder`,
			{ id_parent_folder: tab.id }
		);
		setCurrentFilesAndFolders(response.data);
	};

	return (
		<div className="Tab" onClick={handleClick}>
			<img src={tab.icon} alt={tab.name} />
			<p>{tab.name}</p>
		</div>
	);
};

export default Tab;
